(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("deepmerge"), require("is-plain-object"), require("redux"), require("redux-saga"), require("web3"));
	else if(typeof define === 'function' && define.amd)
		define(["deepmerge", "is-plain-object", "redux", "redux-saga", "web3"], factory);
	else if(typeof exports === 'object')
		exports["@drizzle/store"] = factory(require("deepmerge"), require("is-plain-object"), require("redux"), require("redux-saga"), require("web3"));
	else
		root["@drizzle/store"] = factory(root["deepmerge"], root["is-plain-object"], root["redux"], root["redux-saga"], root["web3"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE_deepmerge__, __WEBPACK_EXTERNAL_MODULE_is_plain_object__, __WEBPACK_EXTERNAL_MODULE_redux__, __WEBPACK_EXTERNAL_MODULE_redux_saga__, __WEBPACK_EXTERNAL_MODULE_web3__) {
return 